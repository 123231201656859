import { PublicPlan } from '@wix/ambassador-pricing-plans-read-api/types';
import { ControllerFlowAPI, ControllerParams } from '@wix/yoshi-flow-editor';
import { PLANS_QUERY_PAGE_SIZE } from '../../constants';
import { TabState } from '../../constants/settings-events';
import { plansFixture } from '../../fixtures';
import { Analytics } from '../../services/analytics';
import { PricingPlansBi } from '../../services/bi';
import { encodeBase64Url } from '../../services/encode-base64';
import { PlansApi } from '../../services/plans';
import { CheckoutData, PopupEnum } from '../../types/common';
import { PlanListInteractions } from '../../types/PlanListFedops';
import { countFullPages, hasMorePages } from '../../utils/pagination';
import { SettingsReader } from '../PackagePicker/DefaultSettingsAdapter';
import { ListProps } from '../PackagePicker/Widget/List';

export class PlanListController {
  constructor(
    public setProps: (props: Partial<ListProps>) => void,
    protected plansApi: PlansApi,
    protected flowAPI: ControllerFlowAPI,
    protected wixCodeApi: ControllerParams['controllerConfig']['wixCodeApi'],
    protected appParams: ControllerParams['controllerConfig']['appParams'],
    protected bi: PricingPlansBi,
    protected analytics: Analytics,
    protected settings: SettingsReader,
  ) {}

  public async initialize() {
    this.flowAPI.fedops.interactionStarted(PlanListInteractions.WidgetLoaded);

    const { plans, hasMorePlans } = await this.fetchPlans();
    this.setProps({
      plans,
      hasMorePlans,
      tabState: TabState.REGULAR,
      selectPlan: this.navigateToCheckout,
      hidePopup: this.hidePopup,
      popup: null,
      loadMore: () => this.fetchMorePlans(plans),
    });

    if (this.flowAPI.environment.isViewer) {
      this.bi.plansPageView(undefined, 'list');
    }
    this.analytics.addProductImpression(plans);
  }

  fetchPlans = async () => {
    let plans: PublicPlan[] = [];
    let hasMorePlans = false;
    if (this.settings.get('usePagination')) {
      const include = this.settings.asArray('visiblePlans');
      plans = await this.plansApi.query(
        include.length > 0 ? { include } : { exclude: this.settings.asArray('hiddenPlans') },
      );
      hasMorePlans = hasMorePages(plans, PLANS_QUERY_PAGE_SIZE);
    } else {
      plans = await this.plansApi.loadPaidPlans({ planIds: [] });
    }
    const useFixture = !plans?.length && this.wixCodeApi.window.viewMode === 'Editor';
    return { plans: useFixture ? plansFixture : plans, hasMorePlans };
  };

  fetchMorePlans = async (currentPlans: PublicPlan[]) => {
    const nextPage = await this.plansApi.query({
      page: countFullPages(currentPlans, PLANS_QUERY_PAGE_SIZE),
      include: this.settings.asArray('visiblePlans'),
    });
    this.setProps({
      plans: [...currentPlans, ...nextPage],
      hasMorePlans: hasMorePages(nextPage, PLANS_QUERY_PAGE_SIZE),
    });
  };

  private navigateToCheckout = async (plan: PublicPlan) => {
    this.flowAPI.fedops.interactionStarted(PlanListInteractions.NavigateToList);

    if (this.wixCodeApi.window.viewMode !== 'Site') {
      this.setProps({ popup: PopupEnum.checkoutPreview });
      this.flowAPI.fedops.interactionEnded(PlanListInteractions.NavigateToList);
      return;
    }

    this.bi.planPurchaseClick(plan.id ?? '', 'list');
    this.analytics.clickProduct(plan);

    const checkoutData: CheckoutData = { planId: plan.id!, integrationData: {} };

    // #membershipPlanPickerTpa1 is default Package Picker velo element id
    // If the user will change it the scroll will stop working and the navigation will be "jumpy"
    this.setCurrentPath('/payment/' + encodeBase64Url(checkoutData) + '#membershipPlanPickerTpa1');
  };

  private hidePopup = () => this.setProps({ popup: null });

  private setCurrentPath = async (path: string) => {
    const { relativeUrl } = await this.wixCodeApi.site.getSectionUrl({
      appDefinitionId: this.appParams.appDefinitionId,
      sectionId: 'membership_plan_picker_tpa',
    });
    this.wixCodeApi.location.to!((relativeUrl ?? '') + path);
    this.flowAPI.fedops.interactionEnded(PlanListInteractions.NavigateToList);
  };
}
