import qs from 'querystring';
import { PublicPlan } from '@wix/ambassador-pricing-plans-read-api/types';
import { Order } from '@wix/ambassador-pricing-plans-v2-order/types';
import * as bi from '@wix/bi-logger-membership/v2';
import { PaymentCompleteResultPublic } from '@wix/cashier-payments-widget/dist/src/sdk/types/PaymentCompleteResult';
import { OwnerLogger, VisitorLogger } from '@wix/yoshi-flow-editor';
import { BenefitBulletType, CheckoutStage, IntegrationData } from '../types/common';
import { Alignment, alignmentName } from '../utils/alignment';
import { benefitBulletTypeName, BIRole, DISPLAY_VALUES, SETTINGS_TABS, toBIPaymentType } from '../utils/bi';
import { getOrderCoupon } from '../utils/domainUtils';
import { PageType } from '../utils/pageType';

type WidgetType = 'page' | 'list';
type Referral = IntegrationData['biOptions'];
type SupportLink = 'Read Support Articles' | 'Contact Customer Care';
type SettingsViewMode = 'desktop' | 'mobile';

interface SettingChanged {
  settingName: string;
  settingsTab: string;
  value: string;
}
export interface CommonBIData {
  appInstanceId: string;
  metaSiteId?: string;
  biToken?: string;
  visitorId?: string;
  siteMemberId?: string;
  role?: BIRole;
}

export class PricingPlansBi {
  constructor(private logger: OwnerLogger | VisitorLogger, private data: CommonBIData) {}

  updateDefaults(defaults: Record<string, any>) {
    return this.logger.updateDefaults(defaults);
  }

  plansPageView(referral: Referral, widgetType: WidgetType = 'list') {
    return this.logger.report(
      bi.plansPageView({
        widgetType,
        referral_info: referral?.referralInfo,
        referralId: referral?.referralId,
        appInstanceId: this.data.appInstanceId,
        biToken: this.data.biToken,
        role: this.data.role,
        site_member_id: this.data.siteMemberId,
        visitor_id: this.data.visitorId,
      }),
    );
  }

  planPurchaseClick(planGuid: string, widgetType: WidgetType = 'list') {
    return this.logger.report(
      bi.planPurchaseClick({
        planGuid,
        widgetType, // list, featured, button -- only list widget exists at the moment.
        appInstanceId: this.data.appInstanceId,
        biToken: this.data.biToken,
        role: this.data.role,
        site_member_id: this.data.siteMemberId,
        visitor_id: this.data.visitorId,
      }),
    );
  }

  thankYouPageCtaButtonClick() {
    return this.logger.report(
      bi.thankYouPageCtaButtonClick({
        appInstanceId: this.data.appInstanceId,
        biToken: this.data.biToken,
        buttonName: 'null',
      }),
    );
  }

  upgradeReferralClick(referralInfo: string) {
    return this.logger.report(
      bi.upgradeReferralClick({
        appInstanceId: this.data.appInstanceId,
        biToken: this.data.biToken,
        isPreview: false,
        referralInfo,
      }),
    );
  }

  checkoutStage(stage: CheckoutStage, planGuid?: string) {
    return this.logger.report(
      bi.checkoutStage({
        planGuid,
        stage,
        appInstanceId: this.data.appInstanceId,
        biToken: this.data.biToken,
      }),
    );
  }

  planPurchased(result: PaymentCompleteResultPublic, plan: PublicPlan, order: Order) {
    return this.logger.report(
      bi.planPurchased({
        appInstanceId: this.data.appInstanceId,
        biToken: this.data.biToken,
        role: this.data.role,
        site_member_id: this.data.siteMemberId,
        duration: plan.paymentOptions?.validFor?.period?.amount,
        paymentType: toBIPaymentType(plan.paymentOptions?.recurring),
        planGuid: plan.id,
        currency: plan.paymentOptions?.price?.currency,
        price: parseFloat(plan.paymentOptions?.price?.amount ?? '0') * 100,
        paymentStatus: result.clientStatus,
        paymentMethodType: result.paymentMethod,
        membershipId: order.id,
        couponId: getOrderCoupon(order)?.id,
      }),
    );
  }

  settingsOpened(settingsViewMode: SettingsViewMode) {
    return this.logger.report(
      bi.settingsOpened({
        appInstanceId: this.data.appInstanceId,
        biToken: this.data.biToken,
        referralInfo: String(qs.parse(window.location.search).referralInfo),
        editor_view_mode: settingsViewMode,
      }),
    );
  }

  widgetSettingsAddOnsModalActions(action: string, options?: { pageName?: string; widgetType?: string }) {
    return this.logger.report(
      bi.widgetSettingsAddOnsModalActions({
        action,
        appInstanceId: this.data.appInstanceId,
        biToken: this.data.biToken,
        ...options,
      }),
    );
  }

  createPlanClick(formOrigin: string) {
    return this.logger.report(
      bi.createPlanClick({
        formOrigin,
        appInstanceId: this.data.appInstanceId,
        biToken: this.data.biToken,
      }),
    );
  }

  widgetClickOnPlanHide(planGuid: string, hidden: boolean) {
    return this.logger.report(
      bi.widgetClickOnPlanHide({
        planGuid,
        hidden,
        appInstanceId: this.data.appInstanceId,
        biToken: this.data.biToken,
      }),
    );
  }

  widgetPlanOrderChanged() {
    return this.logger.report(
      bi.widgetPlanOrderChanged({
        appInstanceId: this.data.appInstanceId,
        biToken: this.data.biToken,
      }),
    );
  }

  actionButtonClicked(action: string, planGuid: string) {
    return this.logger.report(
      bi.actionButtonClicked({
        action,
        planGuid,
        appInstanceId: this.data.appInstanceId,
        biToken: this.data.biToken,
      }),
    );
  }

  planSettingsPanelOpen() {
    return this.logger.report(
      bi.planSettingsPanelOpen({
        appInstanceId: this.data.appInstanceId,
        biToken: this.data.biToken,
      }),
    );
  }

  membershipStatusLoaded(pageType: PageType) {
    return this.logger.report(
      bi.membershipStatusLoaded({
        appInstanceId: this.data.appInstanceId,
        biToken: this.data.biToken,
        role: this.data.role,
        site_member_id: this.data.siteMemberId,
        visitor_id: this.data.visitorId,
        membershipStatus: toMembershipStatus(pageType),
      }),
    );
  }

  editorPlanSettingsContactSupportClick(type: SupportLink) {
    return this.logger.report(
      bi.editorPlanSettingsContactSupportClick({
        appInstanceId: this.data.appInstanceId,
        biToken: this.data.biToken,
        type,
      }),
    );
  }

  widgetSettingChanged({ settingName, settingsTab, value }: SettingChanged) {
    return this.logger.report(
      bi.editorPlanSettingsSettingsPanelChanges({
        appInstanceId: this.data.appInstanceId,
        biToken: this.data.biToken,
        fieldName: settingName,
        settingsType: settingsTab,
        value,
      }),
    );
  }

  visibilitySettingChanged(settingName: string, value: boolean) {
    const valueString = value ? DISPLAY_VALUES.enabled : DISPLAY_VALUES.disabled;
    return this.widgetSettingChanged({ settingName, settingsTab: SETTINGS_TABS.display, value: valueString });
  }

  alignmentSettingChanged(settingName: string, value: Alignment) {
    return this.widgetSettingChanged({
      settingName,
      settingsTab: SETTINGS_TABS.layout,
      value: alignmentName(value),
    });
  }

  benefitBulletTypeSettingChanged(settingName: string, value: BenefitBulletType) {
    return this.widgetSettingChanged({
      settingName,
      settingsTab: SETTINGS_TABS.layout,
      value: benefitBulletTypeName(value),
    });
  }
}

function toMembershipStatus(pageType: PageType) {
  switch (pageType) {
    case PageType.DEFAULT_SUCCESS:
      return 'success';
    case PageType.VERTICAL_SUCCESS:
      return 'vertical_success';
    case PageType.ERROR:
      return 'error';
    default:
      return undefined;
  }
}
